<template>
  <el-dialog
    title="支付"
    :visible.sync="dialogVisible1"
    width="550px"
    class="pay-box"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="payInfo">
      <div>
        <span>支付金额：</span>
        <span class="price">${{ price }}</span>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <span>支付方式：</span>
        <span style="display: flex; align-items: center"
          ><img
            class="pay-icon"
            src="http://gdown.baidu.com/img/0/512_512/aaf27d029984e6d43ae57537e2dfc738.jpg"
            alt=""
          />微信支付</span
        >
      </div>
    </div>

    <div class="codeimg">
      <VueQrcode
        :value="payUrl"
        :options="{ width: 198, height: 202, margin: 0 }"
        style="margin: 0 auto"
      ></VueQrcode>
      <span>請打開手機微信，掃一掃完成支付</span>
    </div>
  </el-dialog>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: { VueQrcode },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    payUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible1: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible1 = true;
    },
    close() {
      this.dialogVisible1 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.pay-box {
  /deep/.el-dialog {
    border-radius: 10px !important;
    z-index: 99999 !important;
  }
  /deep/.el-dialog__wrapper {
    z-index: 999999 !important;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #cccccc;
  }
}

.payInfo {
  text-align: left;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  margin-left: 59px;
  .pay-icon {
    width: 30px;
    height: 24px;
    margin-right: 10px;
  }
  .price {
    font-size: 20px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #f5394c;
  }
}
.codeimg {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    margin-top: 15px;
  }
}
</style>
