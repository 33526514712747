<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      style="z-index: 99999 !important"
    >
      <div class="content">
        <img src="@/assets/mine/组 2999.png" />
        <div class="content-box">
          <div class="tips-text">
            欢迎您使用由香港妇女联合会 (以下亦称“我们”) 提供的会员服务，为使用会员服务 (以下亦称“本服务”) ，请您仔细阅读、理解并遵守《会员服务协议》 (以下亦称“本协议")。本协议由您与香港妇女联合会共同缔结，对双方具有同等法律效力。
          </div>
          <div class="title">信息录入</div>
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addForm"
            class="form-box"
          >
            <el-form-item prop="phone">
              <areaCode
                @setCode="setCode"
                :phoneAreaCode="phoneAreaCode"
              ></areaCode>
              <el-input
                placeholder="请输入手机号码"
                type="number"
                v-model="addForm.phone"
                class="from-input from-input-login"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="realName">
              <el-input
                placeholder="请输入姓名"
                v-model="addForm.realName"
                class="from-input"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="emial">
              <el-input
                placeholder="请输入邮箱"
                v-model="addForm.emial"
                class="from-input"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="sex">
              <el-select v-model="addForm.sex" placeholder="请选择性别">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
            <div @click="addVip('addForm')">
              <el-button  class="login-from-submit"
                >立即支付$68</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>

    <Chioce ref="chioceRef" :price="58" :type="3"/>
  </div>
</template>

<script>
import areaCode from "@/components/areaCode";
import Chioce from './chioce.vue'

export default {
  components: { areaCode,Chioce },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (this.phoneAreaCode != "+86") {
          callback();
        } else {
          const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      }
    };
    var checkEmail = (rule, value, callback) => {
      const reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的邮箱"));
      }
    };
    return {
      dialogVisible: false,
      phoneAreaCode: "+852",
      addForm: {
        phone: "",
        realName: "",
        emial: "",
        sex: "0",
      },
      addFormRules: {
        phone: [{ validator: checkPhone, trigger: "blur", }],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        emial: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // show(){
    //     this.
    // },
    setCode(e) {
      this.phoneAreaCode = e;
    },
    addVip(forname) {
      this.$refs[forname].validate((validate) => {
        if (validate) {
        this.$refs.chioceRef.show()
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #5b3013 !important;
  font-size: 16px;
}
.content {
  position: relative;
  img {
    margin-top: -120px;
  }
  > div {
    text-align: left;
    padding: 0 10px;
  }
  .tips-text {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 40px;
  }
  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .title::before {
    content: " ";
    border-left: 3px solid #f89048;
    padding-left: 5px;
  }
}
.form-box {
  width: 400px;
  margin: 0 auto;
  .el-select {
    width: 100%;
  }
  .login-from-submit {
    width: 100%;
    background: #5b3013;
    border-radius: 28px 28px 28px 28px;
    color: #fff;
  }
  /deep/.el-input__inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    background: #fffbf6 !important;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #fee9d9;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 20px;
    color: #a34a0f !important;
  }
  /deep/.from-input-login .el-input__inner:first-child {
    // text-indent: 50px !important;
    padding-left: 80px;
  }
  /deep/.el-input__inner::placeholder {
    color: #a34a0f !important;
  }
  /deep/.el-dropdown-code,
  /deep/.el-icon-arrow-down {
    color: #a34a0f !important;
  }
}
</style>
