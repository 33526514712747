import axios from "./index.js";
export function addOrder(data) {
  return axios.service({
    url: "/pc/order",
    method: "post",
    data: data,
  });
}

export function addPay(data) {
  return axios.service({
    url: "/pc/order/web/pay",
    method: "post",
    data: data,
  });
}

// 获取订单列表
export function getOrderList(params) {
  return axios.service({
    url: "/pc/order/page",
    method: "get",
    params,
  });
}
export function getOrderDertails(params) {
  return axios.service({
    url: "/pc/order/detail",
    method: "get",
    params,
  });
}

export function getOrderState(params) {
  return axios.service({
    url: "/pc/order/status",
    method: "get",
    params,
  });
}

export function cancelOrder(params) {
  return axios.service({
    url: "/pc/order/cancel",
    method: "get",
    params,
  });
}

// 申请退款
export function orderRefund(params) {
  return axios.service({
    url: "/pc/order/refund",
    method: "get",
    params,
  });
}
