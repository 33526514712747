<template>
  <div>
    <el-dialog
      title="选择支付的货币类型"
      :visible.sync="isdialog"
      width="550px"
      class="pay-box"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="payInfo">
        <div style="margin-bottom: 20px"><span>支付方式：微信支付</span></div>

        <div>
          <span>支付金额：</span>
          <span class="price">${{ price }}</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px">
          <span>货币类型：</span>
          <el-radio-group v-model="radio1" class="ml-4">
            <el-radio label="HKD" size="large">HKD</el-radio>
            <el-radio label="CNY" size="large">CNY</el-radio>
          </el-radio-group>
        </div>

        <div class="btn">
          <el-button type="primary" :loading="loading" @click="submit"
            >确认下单</el-button
          >
        </div>
      </div>
    </el-dialog>
    <Pay :price="price" ref="paybox" :orderNo="orderNo" :payUrl="payurl" />
  </div>
</template>

<script>
import Pay from "./pay.vue";
import { addOrder, addPay } from "@/api/pay.js";
export default {
  components: { Pay },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: null,
    },
    couserId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isdialog: false,
      loading: false,
      radio1: "HKD",
      orderNo: "",
      payurl: "",
    };
  },
  methods: {
    show() {
      this.isdialog = true;
    },
    close() {
      this.isdialog = false;
    },
    submit() {
      this.loading = true;
      addOrder({
        amount: this.price,
        type: Number(this.type),
        id: this.type == 3 ? "" : this.couserId,
      }).then((res) => {
        this.orderNo = res.data.orderNumber;
        addPay({
          orderNumber: this.orderNo,
          pageType: 2,
          clientType: 3,
          feeType: this.radio1,
          returnUrl:
            "https:///swa.org.hk/#/pay-success?type=" +
            this.$route.type +
            "&orderNumber=" +
            this.orderNo +
            "&price=" +
            this.price +
            "&isppf=1",
        }).then((req) => {
          if (req) {
            console.log(req);
            this.payUrl = req.pay_apptrade;
            this.close();
            window.location.href = this.payUrl;
            // this.$refs.paybox.show();
          }
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pay-box {
  /deep/.el-dialog {
    border-radius: 10px !important;
    z-index: 99999 !important;
  }
  /deep/.el-dialog__wrapper {
    z-index: 999999 !important;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #cccccc;
  }
}

.payInfo {
  text-align: left;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  margin-left: 59px;
}
.btn {
  text-align: center;
  margin-left: -30px;
  width: 100%;
  margin-top: 50px;
  .el-button {
    width: 250px;
  }
}
</style>
